import { Spin } from 'antd';
import { rightAuthProps } from 'components/auth/type';
import { Terms } from 'components/common/index';
import React from 'react';
import {
  ErrorDisplay,
  FormInput,
  FormItem,
  PasswordInput,
  StyledForm,
  SubmitSection,
} from './styled';

import { x } from '@xstyled/emotion';

export const RightAuth = (props: rightAuthProps) => {
  const { getFieldDecorator } = props.form;
  return (
    <x.div mt={4}>
      <x.div textAlign="center" fontSize="2xl" fontWeight="bold" color="black">
        Sign in to your account
      </x.div>
      <Spin spinning={props.isLoading}>
        <x.div my={4}>
          <StyledForm onSubmit={props.onLoginButtonClick} layout="vertical">
            <FormItem label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  { required: true, message: 'Email is Required' },
                ],
              })(<FormInput />)}
            </FormItem>
            <FormItem label="Password">
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Password is Required' }],
              })(<PasswordInput />)}
            </FormItem>
            <FormItem>
              <SubmitSection>
                <x.button
                  mt={4}
                  w="full"
                  py={2}
                  borderRadius="md"
                  boxShadow="sm"
                  bg={{ _: 'blue-600', hover: 'blue-700' }}
                  color="white"
                  fontSize="sm"
                  fontWeight="medium"
                >
                  {props.submitButtonText}
                </x.button>
              </SubmitSection>
            </FormItem>
            <ErrorDisplay
              type="error"
              isServerError={props.serverError.isServerError}
              message={props.serverError.serverErrorMessage}
            />
          </StyledForm>
        </x.div>

        <x.div position="relative">
          <x.div
            position="absolute"
            display="flex"
            alignItems="center"
            top={0}
            right={0}
            bottom={0}
            left={0}
          >
            <x.div w="full" borderTop={1} borderColor="gray-300" />
          </x.div>
          <x.div
            position="relative"
            display="flex"
            justifyContent="center"
            fontSize="sm"
          >
            <x.span px={2} bg="white" color="gray-500">
              Or continue with
            </x.span>
          </x.div>
        </x.div>

        <x.div my={4} display="flex" justifyContent="center">
          <x.button
            onClick={props.onGoogleButtonClick}
            py={2}
            px={4}
            bg={{ _: 'white', hover: 'gray-50' }}
            border={1}
            borderColor="gray-300"
            boxShadow="sm"
            borderRadius="md"
            display="inline-flex"
            alignItems="center"
            spaceX={2}
          >
            <x.img w={5} h={5} src={props.googleButton.src as string} />
            <x.span>Google Account</x.span>
          </x.button>
        </x.div>

        <Terms privacy={props.privacy} />
      </Spin>
    </x.div>
  );
};
