import {
  DividerProps,
  LoginFooterProps,
  LogoProps,
  TermsProps,
} from 'components/common/type';
import React from 'react';
import {
  AnchorTag,
  DividerStyle,
  FooterBody,
  LoginFooterStyle,
  StyledLogo,
} from './styled';

import { x } from '@xstyled/emotion';

export const Logo = (props: LogoProps) => (
  <a href={props.href}>
    <StyledLogo src={props.src} />
  </a>
);

export const Divider = (props: DividerProps) => (
  <DividerStyle width={props.width} margin={props.margin} />
);

export const Terms = (props: TermsProps) => (
  <x.div mt={8} fontSize="xs" textAlign="center" color="gray-500">
    By using Gitstart, you agree to our{' '}
    <x.a
      color={{ _: 'gray-500', hover: 'gray-600' }}
      textDecoration="underline"
      href={props.privacy.privacyPolicyLink}
    >
      {props.privacy.policyText}
    </x.a>
    , and our{' '}
    <x.a
      color={{ _: 'gray-500', hover: 'gray-600' }}
      textDecoration="underline"
      href={props.privacy.termsLink}
    >
      {props.privacy.termText}
    </x.a>
    .
  </x.div>
);

export const LoginFooter = (props: LoginFooterProps) => (
  <LoginFooterStyle>
    <AnchorTag href={props.forgetPass.link}>{props.forgetPass.text}</AnchorTag>
    <FooterBody>
      {props.signup.noAccountText}
      <AnchorTag href={props.signup.signupText}>
        {props.signup.signupText}
      </AnchorTag>
    </FooterBody>
  </LoginFooterStyle>
);
