import { Alert, Button, Form, Input } from 'antd';
import { ServerErrorProps } from 'components/auth/type';
import { device } from 'utils/device';
import { styled } from 'utils/styled';

export const TestimonialWrapper = styled('div')`
  flex-direction: row;
  font-size: 14px;
  line-height: 1.8;
  color: ${({ theme }) => theme.fontGreyLight};
`;

export const AuthorName = styled('p')`
  font-size: 12px;
  font-weight: 600;
  margin-top: 1px;
  color: ${({ theme }) => theme.fontGreyLight};
  font-size: 12px;
  margin-bottom: 0px;
  padding: 0;
`;

export const DisplayFlexColumn = styled('div')`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
`;

export const AuthorInfo = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const AuthorPosition = styled('p')`
  font-size: 13px;
  margin-top: 0px;
  padding: 0;
  margin-bottom: -2px;
  color: ${({ theme }) => theme.black};
  opacity: 0.6;
  font-size: 12px;
`;

export const AuthorLogoStyle = styled('img')`
  max-height: 20px;
  max-width: 100px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 10px;
  padding: 3px 0px 5px 14px;
`;

export const Testimonial = styled('p')`
  font-size: 14px;
`;

export const WelcomeBack = styled('h2')`
  font-size: 24px;
  letter-spacing: 1.9px;
  color: ${({ theme }) => theme.fontBlackLight};
`;

export const SignInOAuthButton = styled(Button)`
  background-image: linear-gradient(
    135deg,
    ${({ theme }) => theme.bgWhite},
    ${({ theme }) => theme.bgGrey}
  );
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px 8px 3px 8px;
  padding: 12px 30px 12px 20px;
  height: auto;
  color: ${({ theme }) => theme.fontBlueLight};
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.bgBorder};
  font-size: 16.6px;
  margin: 10px 0px 10px 0px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.bgBorder};
    background-image: linear-gradient(
      135deg,
      ${({ theme }) => theme.bgWhite},
      ${({ theme }) => theme.bgGrey}
    );
    box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const FormInput = styled(Input)`
  height: 36px;
`;

export const PasswordInput = styled(FormInput).attrs({
  type: 'password',
})``;

export const SubmitSection = styled('div')`
  display: block;

  @media (${device.tablet}) {
    display: flex;
    flex-direction: row;
  }
`;

export const OAuthLogo = styled('img')`
  width: 22px;
`;

export const DividerWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 2px 0;
`;

export const ErrorDisplay = styled(Alert)`
  display: ${({ isServerError }: ServerErrorProps) =>
    isServerError ? 'inline-block' : 'none'};
  width: 100%;
  margin: 15px 0px 15px 0px;
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0px;

  label.ant-form-item-required:before {
    content: unset;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;
