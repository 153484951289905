import { DividerProps } from 'components/common/type';
import { device } from 'utils/device';
import { styled } from 'utils/styled';

export const StyledLogo = styled('img')`
  max-height: 50px;
  max-width: 250px;

  @media (${device.tablet}) {
    max-height: 50px;
    max-width: 250px;
  }
`;

export const DividerStyle = styled('hr')`
  width: ${({ width }: DividerProps) => width};
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 25px;
  margin-bottom: 22px;
  margin-${({ margin }: DividerProps) => margin}: 0px;
`;

export const TermsAndPolicy = styled('div')`
  font-size: 12px;
  color: ${({ theme }) => theme.fontGreyLight};
  padding-top: 4px;
  padding-left: 10px;
`;

export const TermTextStyle = styled('p')`
  display: inline;
`;

export const FooterBody = styled('p')`
  font-size: 13.5px;
  padding: 0;
  margin-top: 2px;
`;

export const LoginFooterStyle = styled('div')`
  text-align: right;
  margin-top: 35px;
  width: 90%;

  @media (${device.tablet}) {
    max-width: 840px;
    margin: 0 auto;
    text-align: right;
    margin-top: 20px;
    padding-right: 3%;
  }
`;

export const AnchorTag = styled('a')`
  color: ${({ theme }) => theme.anchorColor};
  font-size: 13.5px;
`;
