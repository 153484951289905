import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { RightAuth } from 'components/auth/rightContainer';
import { Logo } from 'components/common/index';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import googleLogo from 'statics/images/google-icon.svg';
import { signin } from '@GitStartHQ/next-auth/client';

import { x } from '@xstyled/emotion';

const logoData = {
  src:
    'https://assets.website-files.com/5d3536a932a8012e2bfd14b1/5df0aaf2fc0b1a0d4f078377_logo%20blue.png',
  href: '/login',
};

interface serverErrorProps {
  isServerError: boolean;
  serverErrorMessage: string;
}

const Login = (props: FormComponentProps) => {
  const { validateFields } = props.form;
  const router = useRouter();

  const [serverError, setServerError] = useState<serverErrorProps>({
    isServerError: false,
    serverErrorMessage: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loginWithEmail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateFields(async (err: any, values) => {
      if (err) return;

      setIsLoading(true);
      try {
        await signin('credentials', {
          email: values.email,
          password: values.password,
          callbackUrl:
            router.query.redirectUrl && !Array.isArray(router.query.redirectUrl)
              ? router.query.redirectUrl
              : `/login`,
          redirect: true,
        });
      } catch (ex) {
        setServerError({
          isServerError: true,
          serverErrorMessage: (ex as Error).message || (ex as Error).toString(),
        });
      }
      setIsLoading(false);
    });
  };

  const loginWithGoogle = async () => {
    await signin('google', {
      callbackUrl:
        router.query.redirectUrl && !Array.isArray(router.query.redirectUrl)
          ? router.query.redirectUrl
          : `/login`,
      redirect: true,
    });
    // router.replace('/google/login');
  };

  return (
    <x.div
      h="100%"
      bg="#1b1f24"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <x.div
        bg="white"
        borderRadius="md"
        boxShadow="0 10px 15px -3px rgba(255, 255, 255, 0.1), 0 4px 6px -2px rgba(255, 255, 255, 0.05)"
        p={6}
        w="md"
      >
        <x.div display="flex" justifyContent="center" py={4}>
          <Logo {...logoData} />
        </x.div>
        <RightAuth
          form={props.form}
          welcomeMsg="Welcome To GitStart Login Portal!"
          or="or"
          googleButton={{
            src: googleLogo?.src ? googleLogo?.src : '',
            buttonText: 'Sign in with Google',
          }}
          submitButtonText="Login"
          privacy={{
            privacyPolicyLink: 'https://www.gitstart.com/privacy-policy',
            termsLink: 'https://www.gitstart.com/terms',
            policyText: 'privacy policy',
            termText: 'terms',
          }}
          onGoogleButtonClick={loginWithGoogle}
          onLoginButtonClick={loginWithEmail}
          isLoading={isLoading}
          serverError={serverError}
        />
      </x.div>
    </x.div>
  );
};

export default Form.create<FormComponentProps>()(Login);
